export const customToastUtils = {
    success,
    warning,
    error,
    saveSuccess,
    updateSuccess,
    errorOccured,
    deleteSuccess
};

function success(bvToast, title, content) {
    bvToast(
        "success",
        title,
        content
    );
}

function warning(bvToast, title, content) {
    bvToast(
        "warning",
        title,
        content
    );
}

function error(bvToast, title, content) {
    bvToast(
        "error",
        title,
        content
    );
}

function saveSuccess() {
    this.$notify(
        "success",
        'Mise à jour',
        'Mise à jour effectuée'
    );
}
function updateSuccess() {
    this.$notify(
        "success",
        'Mise à jour',
        'Mise à jour effectuée'
    );
}
function errorOccured() {
    this.$notify(
        "error",
        'Erreur',
        'Une erreur s\'est produite. Veuillez réssayer'
    );
}
function deleteSuccess() {
    this.$notify(
        "success",
        'Suppression',
        'Supression effectuée'
    );
}