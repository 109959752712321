import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const messagesService = {
    get,
    reponse,
    update,
    del

};


async function get(limite) {
    const limites = limite != null ? `?limite=${limite}`: '';
    try {
        let rets = await axiosInstance.get(`${baseUrl}/messages${limites}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function reponse(id, data) {

    try {
        let ret = await axiosInstance.put(`/messages/response/${id}`, data);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`/messages/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`/messages/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}

